// extracted by mini-css-extract-plugin
export var blog = "BlogPage-module--blog---bzWl";
export var blurb = "BlogPage-module--blurb--ho9w2";
export var desktopNav = "BlogPage-module--desktopNav--PFW2b";
export var dots = "BlogPage-module--dots--Y8OEs";
export var featured = "BlogPage-module--featured--uTwhm";
export var fourtynineB = "BlogPage-module--fourtynine-b--DORw3";
export var heading = "BlogPage-module--heading--pgq+N";
export var hide = "BlogPage-module--hide--BH2XX";
export var inputLabel = "BlogPage-module--input-label--MXSW8";
export var mobileNav = "BlogPage-module--mobileNav--4oPf9";
export var nav = "BlogPage-module--nav--PBNAF";
export var navHighlight = "BlogPage-module--navHighlight--NBM-I";
export var navigation = "BlogPage-module--navigation---Exth";
export var next = "BlogPage-module--next--YdQ5q";
export var number = "BlogPage-module--number--x4-dn";
export var numberNavigation = "BlogPage-module--numberNavigation--2OA2u";
export var pageNumber = "BlogPage-module--pageNumber--qrM-x";
export var prev = "BlogPage-module--prev--O9BDY";
export var seventysixB = "BlogPage-module--seventysix-b--aaAt-";
export var sixteenB = "BlogPage-module--sixteen-b--zdgJF";
export var sixteenR = "BlogPage-module--sixteen-r--8exok";
export var thirteenMK = "BlogPage-module--thirteen-m-k--Rw9D5";
export var thirtynineB = "BlogPage-module--thirtynine-b---qlyE";
export var thirtyoneB = "BlogPage-module--thirtyone-b--VmmxX";
export var title = "BlogPage-module--title--yg6qd";
export var twentyB = "BlogPage-module--twenty-b--TR7Nk";
export var twentyR = "BlogPage-module--twenty-r--TGxVh";
export var twentyfiveB = "BlogPage-module--twentyfive-b--16Ot6";
export var wrapper = "BlogPage-module--wrapper--RSJMp";