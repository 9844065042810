import * as React from 'react'
import { Link, PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { ArticleTile } from '../components/ArticleTile'
import { ArticleList } from '../components/ArticleList'

import * as styles from './BlogPage.module.scss'
import { articleList as articleListStyle } from '../components/ArticleList.module.scss'
import { SEOSocialMeta } from '../components/SEOSocialMeta'

type Image = { url: string; alt: string; gatsbyImageData: any }
type BlogPageProps = {
  data: Queries.BlogPageQuery
  pageContext: {
    limit: number
    skip: number
    not: string | null
    numPages: number
    currentPage: number
    prev: string | undefined
    next: string | undefined
  }
}

const BlogPage = ({ data, pageContext }: BlogPageProps) => {
  if (!data || !data.prismicBlogPage) return null
  const doc = data.prismicBlogPage.data
  let articleNodes = data.allPrismicArticle.nodes
  let articles = articleNodes.map((article) => ({
    slug: article.uid,
    featuredImage: article.data.featured_image,
    title: article.data.title?.text,
  }))
  let pageNumbersDesktop: (string | number)[] = [pageContext.currentPage]
  let pageNumbersMobile: (string | number)[] = [pageContext.currentPage]
  const desktopLimit = 10
  const mobileLimit = 5
  for (
    let i = 1;
    pageNumbersDesktop.length < desktopLimit ||
    pageNumbersDesktop.length < pageContext.numPages;
    i++
  ) {
    if (i % 2 === 0) {
      if (pageContext.currentPage - Math.ceil(i / 2) > 0) {
        pageNumbersDesktop.unshift(pageContext.currentPage - Math.ceil(i / 2))
        if (pageNumbersMobile.length < mobileLimit) {
          pageNumbersMobile.unshift(pageContext.currentPage - Math.ceil(i / 2))
        }
      }
    } else {
      if (pageContext.currentPage + Math.ceil(i / 2) <= pageContext.numPages) {
        pageNumbersDesktop.push(pageContext.currentPage + Math.ceil(i / 2))
        if (pageNumbersMobile.length < mobileLimit) {
          pageNumbersMobile.push(pageContext.currentPage + Math.ceil(i / 2))
        }
      }
    }
    if (
      pageNumbersDesktop.length >= desktopLimit ||
      (pageNumbersDesktop[0] === 1 &&
        pageNumbersDesktop[pageNumbersDesktop.length - 1] ===
          pageContext.numPages)
    ) {
      break
    }
  }
  if (pageNumbersDesktop[0] != 1) {
    pageNumbersDesktop[1] = '...'
    pageNumbersDesktop[0] = 1
  }
  if (
    pageNumbersDesktop[pageNumbersDesktop.length - 1] != pageContext.numPages
  ) {
    pageNumbersDesktop[pageNumbersDesktop.length - 2] = '...'
    pageNumbersDesktop[pageNumbersDesktop.length - 1] = pageContext.numPages
  }
  if (pageNumbersMobile[0] != 1) {
    pageNumbersMobile[1] = '...'
    pageNumbersMobile[0] = 1
  }
  if (pageNumbersMobile[pageNumbersMobile.length - 1] != pageContext.numPages) {
    pageNumbersMobile[pageNumbersMobile.length - 2] = '...'
    pageNumbersMobile[pageNumbersMobile.length - 1] = pageContext.numPages
  }
  React.useEffect(() => {
    if (location.state && location.state.scrollToList) {
      document.getElementsByClassName(articleListStyle)[0].scrollIntoView()
    }
  })
  return (
    <Layout>
      <SEOSocialMeta
        facebook={{
          description: doc.facebook_description,
          image: doc.facebook_image?.url,
          title: doc.facebook_title,
        }}
        seo={{
          description: doc.seo_description,
          title: doc.seo_title,
        }}
        twitter={{
          description: doc.twitter_description,
          image: doc.twitter_image?.url,
          title: doc.twitter_title,
        }}
      />
      <div className={styles.blog}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Blog</div>
          <h1 className={styles.heading}>{doc.title?.text}</h1>
          <div className={styles.blurb}>
            <PrismicRichText field={doc.blurb?.richText} />
          </div>
          {pageContext.currentPage === 1 && doc.featured_post?.document && (
            <div className={styles.featured}>
              {Object.keys(doc.featured_post.document).length !== 0 && (
                <ArticleTile
                  featured
                  slug={
                    (doc.featured_post.document as Queries.PrismicArticle)
                      .uid ?? ''
                  }
                  image={
                    ((doc.featured_post.document as Queries.PrismicArticle).data
                      .featured_image as Image) ?? {
                      url: '',
                      alt: '',
                      gatsbyImageData: null,
                    }
                  }
                  title={
                    (doc.featured_post.document as Queries.PrismicArticle).data
                      .title?.text ?? ''
                  }
                />
              )}
            </div>
          )}
          <ArticleList articles={articles} limit={6} twoColumnsMax />
          {(pageContext.prev || pageContext.next) && (
            <div className={styles.navigation}>
              <div className={styles.numberNavigation}>
                <Link
                  to={pageContext.prev || ''}
                  state={{ scrollToList: true }}
                >
                  <div
                    className={`icon-back ${styles.nav} ${styles.prev} ${
                      pageContext.prev ? '' : styles.hide
                    }`}
                  >
                    Back
                  </div>
                </Link>
                <div className={`${styles.desktopNav}`}>
                  {pageNumbersDesktop.map((i, index: number) =>
                    !isNaN(i as number) && i != pageContext.currentPage ? (
                      <Link
                        to={i === 1 ? '/blog/' : `/blog/${i}`}
                        state={{ scrollToList: true }}
                        tabIndex={0}
                      >
                        <div key={index} className={styles.number}>
                          {i}
                        </div>
                      </Link>
                    ) : (
                      <div
                        key={index}
                        className={`${
                          isNaN(i as number) ? styles.dots : styles.number
                        } ${
                          i === pageContext.currentPage
                            ? styles.navHighlight
                            : ''
                        }`}
                      >
                        {i}
                      </div>
                    )
                  )}
                </div>
                <div className={`${styles.mobileNav}`}>
                  {pageNumbersMobile.map((i, index: number) =>
                    !isNaN(i as number) && i != pageContext.currentPage ? (
                      <Link
                        to={i === 1 ? '/blog/' : `/blog/${i}`}
                        state={{ scrollToList: true }}
                        tabIndex={0}
                      >
                        <div key={index} className={styles.number}>
                          {i}
                        </div>
                      </Link>
                    ) : (
                      <div
                        key={index}
                        className={`${
                          isNaN(i as number) ? styles.dots : styles.number
                        } ${
                          i === pageContext.currentPage
                            ? styles.navHighlight
                            : ''
                        }`}
                      >
                        {i}
                      </div>
                    )
                  )}
                </div>
                <Link
                  to={pageContext.next || ''}
                  state={{ scrollToList: true }}
                >
                  <div
                    className={`icon-back ${styles.nav} ${styles.next} ${
                      pageContext.next ? '' : styles.hide
                    }`}
                  >
                    Next
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogPage($skip: Int!, $limit: Int!, $not: String) {
    prismicBlogPage {
      data {
        ...BlogPageDataSEOSocialMeta
        blurb {
          richText
        }
        title {
          text
        }
        featured_post {
          document {
            ... on PrismicArticle {
              id
              uid
              data {
                featured_image {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                  url
                  alt
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
    allPrismicArticle(
      sort: { fields: first_publication_date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { id: { ne: $not } }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          featured_image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            url
            alt
          }
        }
      }
    }
  }
`

export default withPrismicPreview(
  BlogPage as ({
    data,
    pageContext,
  }: PageProps<Queries.BlogPageQuery>) => JSX.Element | null
)
